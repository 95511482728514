/* istanbul ignore file */
/* tslint:disable */

export enum ProductDType {
  BEETLE = 'BEETLE',
  CLIENT = 'CLIENT',
  IN_HOUSE = 'IN_HOUSE',
  IOT_GATEWAY = 'IOT_GATEWAY',
  IOT = 'IOT',
  ON_SITE_PANEL_SERVER = 'ON_SITE_PANEL_SERVER',
  RECURRING_BEETLE = 'RECURRING_BEETLE',
  RECURRING_CLIENT = 'RECURRING_CLIENT',
  RECURRING_IOT_GATEWAY = 'RECURRING_IOT_GATEWAY',
  RECURRING_IOT = 'RECURRING_IOT',
  RECURRING_ON_SITE_PANEL_SERVER = 'RECURRING_ON_SITE_PANEL_SERVER',
  RECURRING_PRODUCT = 'RECURRING_PRODUCT',
  RECURRING_TABLET = 'RECURRING_TABLET',
  RECURRING_WEATHER = 'RECURRING_WEATHER',
  RECURRING_VIRTUAL_DEVICE = 'RECURRING_VIRTUAL_DEVICE',
  RECURRING_WAGO_750_MQTT = 'RECURRING_WAGO_750_MQTT',
  SUPPORT_HOUR = 'SUPPORT_HOUR',
  TABLET = 'TABLET',
  VIRTUAL_DEVICE = 'VIRTUAL_DEVICE',
  WAGO_750_MQTT = 'WAGO_750_MQTT',
  TRACKER = 'TRACKER',
  WEATHER = 'WEATHER'
}
