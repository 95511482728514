import {CdkDrag, CdkDragHandle} from '@angular/cdk/drag-drop';
import {NgIf} from '@angular/common';
import {Component, Input} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogRef} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TranslocoModule} from '@ngneat/transloco';

@Component({
  selector: 'alc-dialog-title',
  standalone: true,
  imports: [CdkDrag, CdkDragHandle, MatIconModule, MatButtonModule, MatTooltipModule, TranslocoModule, NgIf],
  templateUrl: './dialog-title.component.html',
  styleUrls: ['./dialog-title.component.scss']
})
export class DialogTitleComponent {
  @Input() title: string;
  @Input() showTitleTooltip: boolean;
  @Input() closeParameter: any;
  @Input() hideCloseButton: boolean;

  constructor(public dialogRef: MatDialogRef<any>) {}
}
