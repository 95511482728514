<alc-dialog-title [title]="'LICENSE.TITLE.EXCEEDED_LICENSES' | transloco"></alc-dialog-title>
<mat-dialog-content class="mat-typography">
  <alc-message class="warning ng-hide" icon="warning">
    <span>{{ "LICENSE.MESSAGE.EXCEEDED_LICENSES_WARNING" | transloco }}</span>
    <span>{{ "LICENSE.MESSAGE.EXCEEDED_LICENSES_SOLUTION" | transloco }}</span>
  </alc-message>
  <table class="licenses">
    <tr>
      <th>{{ "LICENSE.COLUMN.DEVICE" | transloco }}</th>
      <th>{{ "LICENSE.COLUMN.PACKAGE" | transloco }}</th>
      <th class="license-count-header">{{ "LICENSE.COLUMN.LICENSE_EXCEEDED_BY" | transloco }}</th>
      <th></th>
    </tr>
    <tr *ngFor="let entry of exceededLicenseEntries">
      <td [innerText]="entry.deviceName"></td>
      <td class="nowrap" [innerText]="entry.packageLabel"></td>
      <td class="nowrap license-count" [innerText]="entry.exceededDataPointsLabel"></td>
      <td>
        <button
          [matTooltip]="'TOOLTIP.GO_TO_DEVICE' | transloco"
          aria-label="Go to Device"
          mat-icon-button
          (click)="goToDeviceDetails(entry.deviceId, entry.deviceType)">
          <mat-icon>open_in_new</mat-icon>
        </button>
      </td>
    </tr>
  </table>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button mat-dialog-close>
    <span>{{ "BUTTON.CLOSE" | transloco }}</span>
  </button>
</mat-dialog-actions>
