/* istanbul ignore file */
/* tslint:disable */

export enum AggregationType {
  DEFAULT = 'default',
  RAW = 'raw',
  AVG = 'avg',
  MIN = 'min',
  MAX = 'max',
  SUM = 'sum',
  CONSUMPTION_POSITIVE = 'consumption_positive',
  CONSUMPTION = 'consumption',
  CONSUMPTION_NEGATIVE = 'consumption_negative',
  CONSUMPTION_ALL = 'consumption_all',
  NR_OF_ALARMS = 'nr_of_alarms',
  TOTAL_DURATION = 'total_duration',
  DATAPOINT_CATEGORY = 'datapoint_category',
  LAST_VALUE = 'last_value'
}
