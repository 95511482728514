/* istanbul ignore file */
/* tslint:disable */

export enum LineStyle {
  SOLID = 'solid',
  LONG_DASH = 'long_dash',
  DASH = 'dash',
  SHORT_DASH = 'short_dash',
  DOT = 'dot',
  SHORT_DOT = 'short_dot',
  LONG_DASH_DOT = 'long_dash_dot',
  DASH_DOT = 'dash_dot',
  SHORT_DASH_DOT = 'short_dash_dot',
  LONG_DASH_DOT_DOT = 'long_dash_dot_dot',
  SHORT_DASH_DOT_DOT = 'short_dash_dot_dot'
}
