import {ApplicationRef, Component, OnInit, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, NavigationEnd, NavigationError, NavigationStart, Router, RouterOutlet} from '@angular/router';
import {SwUpdate, VersionReadyEvent} from '@angular/service-worker';
import {TranslocoService} from '@ngneat/transloco';
import {CgBusyDefaults} from 'angular-busy2';
import {Settings} from 'luxon';
import {ToastrService} from 'ngx-toastr';
import {combineLatest, concat, interval, merge, Observable, of} from 'rxjs';
import {filter, first, map, switchMap} from 'rxjs/operators';
import {environment} from '../environments/environment';
import {SplashScreenComponent} from './core/landing-page/splash-screen/splash-screen.component';
import {lang as LanguageSetting} from './product-information.constant';
import {ErrorsLogEntity} from './shared/entities/errors-log/error-log.entity';
import {Alcedo7User, selectedClientChange$} from './shared/entities/user/avelon-user.service';
import {checkDisplayToast} from './shared/services/release-version';
import {StatusCheckService} from './shared/services/status-check.service';
import {ThemingService} from './shared/services/theming.service';
import {Translate} from './shared/services/translate.service';

@Component({
  selector: 'alc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet, SplashScreenComponent]
})
export class AppComponent implements OnInit {
  languages: string[] = ['en', 'de', 'fr', 'it'];

  @ViewChild('customBusyTemplate', {static: true})
  private set customBusyTemplateTpl(customBusyTemplateTpl: TemplateRef<any>) {
    this.busyDefaults.templateRef = customBusyTemplateTpl;
  }

  constructor(
    public translate: TranslocoService,
    private busyDefaults: CgBusyDefaults,
    private updates: SwUpdate,
    private title: Title,
    private route: ActivatedRoute,
    private router: Router,
    private appRef: ApplicationRef,
    private toastrService: ToastrService,
    private errorsLogEntity: ErrorsLogEntity, // needed to init the error log listener
    private statusCheckService: StatusCheckService,
    private themingService: ThemingService,
    public viewContainerRef: ViewContainerRef, // needed for alc-tooltip
    dialog: MatDialog
  ) {
    Translate.setInstance(translate);
    translate.setDefaultLang('en');
    const locale = AppComponent.getLang();
    const lang = locale.split('-')[0];
    if (this.languages.indexOf(lang) > -1) {
      translate.setActiveLang(lang);
      Settings.defaultLocale = locale;
      LanguageSetting.locale = locale;
    }

    this.initTitle();

    this.initServiceWorkerChecks();

    window.addEventListener('online', () => this.statusCheckService.stopCheck());
    window.addEventListener('offline', () => this.statusCheckService.startCheck());

    router.events.subscribe(e => {
      if (e instanceof NavigationError && e?.url.startsWith('/?state=')) {
        // Error: Uncaught (in promise): could not find matching config for state
        // this happens when resetting password and user opens email link in a new browser tab
        // the oidc library should authorize() again instead of throwing an error
        location.href = '/';
      }
      if (e instanceof NavigationStart) {
        dialog.openDialogs.forEach(dialogRef => {
          if (dialogRef.id !== 'releaseNotes' && dialogRef.id !== 'panelStatusDialog') {
            dialogRef.close();
          }
        });
      }
    });
  }

  static getLang(): string {
    if (navigator.languages !== undefined && navigator.languages[0]) {
      return navigator.languages[0];
    } else {
      return navigator.language;
    }
  }

  ngOnInit() {
    this.themingService.darkTheme.subscribe((darkThemeEnabled: boolean) => {
      document.body.classList.toggle('avelon-dark-theme', darkThemeEnabled);
    });
  }

  private initTitle(): void {
    const routeChange$ = this.router.events.pipe(filter(e => e instanceof NavigationEnd));

    merge(routeChange$, selectedClientChange$)
      .pipe(switchMap(() => this.getFirstPartTitle()))
      .subscribe(firstPart => this.updateTitle(firstPart));
  }

  private getFirstPartTitle(): Observable<string | undefined> {
    let title: string;
    let route = this.route;
    while (route.firstChild) {
      route = route.firstChild;
      if (route.routeConfig && route.routeConfig.data && route.routeConfig.data.title) {
        title = route.routeConfig.data.title;
      }
    }
    if (title) {
      return this.translate.selectTranslate(title).pipe(
        map(firstPart => {
          if (firstPart === title) {
            return undefined;
          } else {
            return firstPart;
          }
        })
      );
    } else {
      return of(undefined);
    }
  }

  private updateTitle(firstPart: string | undefined): void {
    let secondPart = location.host.includes('building.wago.cloud') ? 'WAGO' : 'Avelon';
    if (Alcedo7User.selectedClient) {
      secondPart = Alcedo7User.selectedClient.name;
    }
    if (firstPart && secondPart) {
      this.title.setTitle(firstPart + ' - ' + secondPart);
    } else if (firstPart) {
      this.title.setTitle(firstPart);
    } else if (secondPart) {
      this.title.setTitle(secondPart);
    }
  }

  private initServiceWorkerChecks(): void {
    if (this.updates.isEnabled) {
      const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
      const everySixHours$ = interval(2 * 60 * 60 * 1000); // check every 2 hours
      const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

      everySixHoursOnceAppIsStable$.subscribe(() => this.updates.checkForUpdate());

      this.updates.versionUpdates
        .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
        .subscribe(event => checkDisplayToast(event, environment.version, () => this.displayUpdateToast()));
    }
  }

  private displayUpdateToast(): void {
    const titleTranslate = this.translate.selectTranslate('GENERAL.UPDATE.TITLE');
    const bodyTranslate = this.translate.selectTranslate('GENERAL.UPDATE.MESSAGE');
    combineLatest([titleTranslate, bodyTranslate]).subscribe(response => {
      if (location.pathname.includes('slides') || document.body.className.includes('full-screen-mode')) {
        this.updates.activateUpdate().then(() => document.location.reload());
      } else {
        this.toastrService
          .info(response[1], response[0], {disableTimeOut: true})
          .onTap.subscribe(() => this.updates.activateUpdate().then(() => document.location.reload()));
      }
    });
  }
}
