import {OpenIdConfiguration} from 'angular-auth-oidc-client/lib/config/openid-configuration';
import {environment} from '../environments/environment';
import {apiVersion} from './api-version.constant';
import {LocalStorageKeys} from './shared/constants/local-storage-keys.constant';
import {JSONUtils} from './shared/services/json-utils.service';

export const PanelAuthConfigId = 'panelBaseSystem';

const AuthConfig: OpenIdConfiguration = {
  configId: 'main',
  authority: '/auth/realms/avelon',
  triggerAuthorizationResultEvent: true,
  redirectUrl: environment.production ? '/' : window.location.origin + '/',
  postLogoutRedirectUri: environment.production ? '/' : window.location.origin,
  clientId: 'avelon-web-front-end',
  scope: 'openid offline_access',
  responseType: 'code',
  silentRenew: true,
  // logLevel: 1, // used for debug
  ignoreNonceAfterRefresh: true,
  issValidationOff: !environment.production,
  useRefreshToken: true,
  autoUserInfo: false,
  renewTimeBeforeTokenExpiresInSeconds: 60,
  ngswBypass: true,
  secureRoutes: [apiVersion, 'graphql/', 'auth/']
};
const configs = [AuthConfig];

if (localStorage.getItem(LocalStorageKeys.Panel.baseSystemUrl)) {
  const PanelAuthConfig = JSONUtils.clone(AuthConfig);
  PanelAuthConfig.configId = PanelAuthConfigId;
  PanelAuthConfig.authority = localStorage.getItem(LocalStorageKeys.Panel.baseSystemUrl) + AuthConfig.authority;
  PanelAuthConfig.redirectUrl = window.location.origin + window.location.pathname;
  PanelAuthConfig.postLogoutRedirectUri = window.location.origin + window.location.pathname;
  configs.push(PanelAuthConfig);
}
export const AuthConfigurations = configs;
