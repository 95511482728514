/* istanbul ignore file */
/* tslint:disable */

export enum CustomTime {
  DAYS = 'days',
  WEEKS = 'weeks',
  MONTHS = 'months',
  QUARTERS = 'quarters',
  SEMESTERS = 'semesters',
  YEARS = 'years'
}
