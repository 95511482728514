import {TranslocoService} from '@ngneat/transloco';
import {Observable, of} from 'rxjs';

export class Translate {
  private static instance: TranslocoService = {
    translate: (key: string | Array<string>, interpolateParams?: any) => key,
    selectTranslate: (key: string | Array<string>, interpolateParams?: any) => of(key)
  } as any;

  static getInstance() {
    return Translate.instance;
  }

  static instant(key: string | Array<string>, interpolateParams?: any): string | any {
    return Translate.instance.translate(key, interpolateParams);
  }

  static get(key: string | Array<string>, interpolateParams?: any): Observable<string | any> {
    return Translate.instance.selectTranslate(key, interpolateParams);
  }

  static setInstance(translate: TranslocoService) {
    Translate.instance = translate;
  }
}
