/* istanbul ignore file */
/* tslint:disable */

export enum TicketListColumn {
  ID = 'id',
  STATUS = 'status',
  CREATED = 'created',
  MODIFIED = 'modified',
  RESOLVED = 'resolved',
  TYPE = 'type',
  TEXT = 'text',
  TAGS = 'tags',
  SOURCE = 'source',
  ALARMCHAIN = 'alarmchain',
  RESPONSIBLE = 'responsible',
  DUE = 'due',
  BUILDING = 'building',
  ALARMINSTRUCTION = 'alarminstruction',
  REALESTATE = 'realestate'
}
