/* istanbul ignore file */
/* tslint:disable */

export enum AuxiliaryType {
  LINEAR = 'linear',
  SEGMENTED_LINEAR = 'segmented_linear',
  POLYNOMIAL = 'polynomial',
  EXPONENTIAL = 'exponential',
  LOGARITHMIC = 'logarithmic'
}
