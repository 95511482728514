/* istanbul ignore file */
/* tslint:disable */

export enum ExcelCellHorizontalAlignment {
  GENERAL = 'GENERAL',
  LEFT = 'LEFT',
  CENTER = 'CENTER',
  RIGHT = 'RIGHT',
  FILL = 'FILL',
  JUSTIFY = 'JUSTIFY',
  CENTER_SELECTION = 'CENTER_SELECTION',
  DISTRIBUTED = 'DISTRIBUTED'
}
