import {Subject} from 'rxjs';
import {Client, UserSession} from './avelon-user.interface';

let selectedClient: Client = null;

export const selectedClientChange$ = new Subject<void>();

export const Alcedo7User: UserSession = {
  clients: [],
  isIoT: false,
  currentUser: null,
  get selectedClient(): Client {
    return selectedClient;
  },
  set selectedClient(client: Client) {
    selectedClient = client;
    selectedClientChange$.next();
  },
  token: ''
};

export function updateUserName(user): any {
  if (user.firstName) {
    user.name = user.firstName + ' ' + user.lastName;
  } else {
    user.name = user.lastName;
  }
  return user;
}

export function updateTenantName(user): any {
  if (user.firstname) {
    user.name = user.firstname + ' ' + user.lastname;
  } else {
    user.name = user.lastname;
  }
  return user;
}
