import {marker} from '@ngneat/transloco-keys-manager/marker';
import {ChartType, CustomPeriod, CustomTime, SmartTime, WidgetType} from 'api/entities';
import {DateTime} from 'luxon';
import {Translate} from '../../services/translate.service';
import {SmartDatePeriods} from './widgets.interface';

export const SmartDatePeriodsMap: SmartDatePeriods = {
  [SmartTime.TODAY]: {
    periodType: CustomPeriod.CURRENT,
    periodValue: 1,
    periodTime: CustomTime.DAYS,
    offsetValue: 0,
    offsetTime: CustomTime.DAYS,
    value: SmartTime.TODAY,
    get label() {
      return Translate.instant(marker('TIME_SELECTION.DATE.TODAY'));
    }
  },
  [SmartTime.YESTERDAY]: {
    periodType: CustomPeriod.PREVIOUS,
    periodValue: 1,
    periodTime: CustomTime.DAYS,
    offsetValue: 0,
    offsetTime: CustomTime.DAYS,
    value: SmartTime.YESTERDAY,
    get label() {
      return Translate.instant(marker('TIME_SELECTION.DATE.YESTERDAY'));
    }
  },
  [SmartTime.LAST_7_DAYS]: {
    periodType: CustomPeriod.CURRENT,
    periodValue: 7,
    periodTime: CustomTime.DAYS,
    offsetValue: 0,
    offsetTime: CustomTime.WEEKS,
    value: SmartTime.LAST_7_DAYS,
    get label() {
      return Translate.instant(marker('TIME_SELECTION.DATE.LAST_SEVEN_DAYS'));
    }
  },
  [SmartTime.LAST_30_DAYS]: {
    periodType: CustomPeriod.CURRENT,
    periodValue: 30,
    periodTime: CustomTime.DAYS,
    offsetValue: 0,
    offsetTime: CustomTime.DAYS,
    value: SmartTime.LAST_30_DAYS,
    get label() {
      return Translate.instant(marker('TIME_SELECTION.DATE.LAST_THIRTY_DAYS'));
    }
  },
  [SmartTime.LAST_365_DAYS]: {
    periodType: CustomPeriod.CURRENT,
    periodValue: 365,
    periodTime: CustomTime.DAYS,
    offsetValue: 0,
    offsetTime: CustomTime.DAYS,
    value: SmartTime.LAST_365_DAYS,
    get label() {
      return Translate.instant(marker('TIME_SELECTION.DATE.LAST_THREE_HUNDRED_SIXTY_FIVE_DAYS'));
    }
  },
  [SmartTime.THIS_WEEK]: {
    periodType: CustomPeriod.CURRENT,
    periodValue: 1,
    periodTime: CustomTime.WEEKS,
    offsetValue: 0,
    offsetTime: CustomTime.WEEKS,
    value: SmartTime.THIS_WEEK,
    get label() {
      return Translate.instant(marker('TIME_SELECTION.DATE.THIS_WEEK'));
    }
  },
  [SmartTime.LAST_WEEK]: {
    periodType: CustomPeriod.PREVIOUS,
    periodValue: 1,
    periodTime: CustomTime.WEEKS,
    offsetValue: 0,
    offsetTime: CustomTime.WEEKS,
    value: SmartTime.LAST_WEEK,
    get label() {
      return Translate.instant(marker('TIME_SELECTION.DATE.LAST_WEEK'));
    }
  },
  [SmartTime.THIS_MONTH]: {
    periodType: CustomPeriod.CURRENT,
    periodValue: 1,
    periodTime: CustomTime.MONTHS,
    offsetValue: 0,
    offsetTime: CustomTime.MONTHS,
    value: SmartTime.THIS_MONTH,
    get label() {
      return Translate.instant(marker('TIME_SELECTION.DATE.THIS_MONTH'));
    }
  },
  [SmartTime.LAST_MONTH]: {
    periodType: CustomPeriod.PREVIOUS,
    periodValue: 1,
    periodTime: CustomTime.MONTHS,
    offsetValue: 0,
    offsetTime: CustomTime.MONTHS,
    value: SmartTime.LAST_MONTH,
    get label() {
      return Translate.instant(marker('TIME_SELECTION.DATE.LAST_MONTH_STR'));
    }
  },
  [SmartTime.THIS_QUARTER]: {
    periodType: CustomPeriod.CURRENT,
    periodValue: 1,
    periodTime: CustomTime.QUARTERS,
    offsetValue: 0,
    offsetTime: CustomTime.QUARTERS,
    value: SmartTime.THIS_QUARTER,
    get label() {
      return Translate.instant(marker('TIME_SELECTION.DATE.THIS_QUARTER'));
    }
  },
  [SmartTime.LAST_QUARTER]: {
    periodType: CustomPeriod.PREVIOUS,
    periodValue: 1,
    periodTime: CustomTime.QUARTERS,
    offsetValue: 0,
    offsetTime: CustomTime.QUARTERS,
    value: SmartTime.LAST_QUARTER,
    get label() {
      return Translate.instant(marker('TIME_SELECTION.DATE.LAST_QUARTER'));
    }
  },
  [SmartTime.THIS_SEMESTER]: {
    periodType: CustomPeriod.CURRENT,
    periodValue: 1,
    periodTime: CustomTime.SEMESTERS,
    offsetValue: 0,
    offsetTime: CustomTime.SEMESTERS,
    value: SmartTime.THIS_SEMESTER,
    get label() {
      return Translate.instant(marker('TIME_SELECTION.DATE.THIS_SEMESTER'));
    }
  },
  [SmartTime.LAST_SEMESTER]: {
    periodType: CustomPeriod.PREVIOUS,
    periodValue: 1,
    periodTime: CustomTime.SEMESTERS,
    offsetValue: 0,
    offsetTime: CustomTime.SEMESTERS,
    value: SmartTime.LAST_SEMESTER,
    get label() {
      return Translate.instant(marker('TIME_SELECTION.DATE.LAST_SEMESTER'));
    }
  },
  [SmartTime.THIS_YEAR]: {
    periodType: CustomPeriod.CURRENT,
    periodValue: 1,
    periodTime: CustomTime.YEARS,
    offsetValue: 0,
    offsetTime: CustomTime.YEARS,
    value: SmartTime.THIS_YEAR,
    get label() {
      return Translate.instant(marker('TIME_SELECTION.DATE.THIS_YEAR'));
    }
  },
  [SmartTime.LAST_YEAR]: {
    periodType: CustomPeriod.PREVIOUS,
    periodValue: 1,
    periodTime: CustomTime.YEARS,
    offsetValue: 0,
    offsetTime: CustomTime.YEARS,
    value: SmartTime.LAST_YEAR,
    get label() {
      return Translate.instant(marker('TIME_SELECTION.DATE.LAST_YEAR'));
    }
  },
  [SmartTime.ANCILLARY_COST_SETTLEMENT_JUL_JUN]: {
    get periodType() {
      return DateTime.local().month >= 7 ? CustomPeriod.CURRENT : CustomPeriod.PREVIOUS;
    },
    periodValue: 1,
    periodTime: CustomTime.YEARS,
    offsetValue: 6,
    offsetTime: CustomTime.MONTHS,
    value: SmartTime.ANCILLARY_COST_SETTLEMENT_JUL_JUN,
    get label() {
      return Translate.instant(marker('TIME_SELECTION.DATE.ANCILLARY_COST_SETTLEMENT_JUL_JUN'));
    }
  },
  [SmartTime.ANCILLARY_COST_SETTLEMENT_MAY_APR]: {
    get periodType() {
      return DateTime.local().month >= 5 ? CustomPeriod.CURRENT : CustomPeriod.PREVIOUS;
    },
    periodValue: 1,
    periodTime: CustomTime.YEARS,
    offsetValue: 4,
    offsetTime: CustomTime.MONTHS,
    value: SmartTime.ANCILLARY_COST_SETTLEMENT_MAY_APR,
    get label() {
      return Translate.instant(marker('TIME_SELECTION.DATE.ANCILLARY_COST_SETTLEMENT_MAY_APR'));
    }
  },
  [SmartTime.DISCHARGE_YEAR_NOV_OCT]: {
    // In December, display the current period instead of the previous one.
    // We skip November to make sure that consumptions are calculated completely in November.
    get periodType() {
      return DateTime.local().month >= 12 ? CustomPeriod.CURRENT : CustomPeriod.PREVIOUS;
    },
    periodValue: 1,
    periodTime: CustomTime.YEARS,
    offsetValue: 10,
    offsetTime: CustomTime.MONTHS,
    value: SmartTime.DISCHARGE_YEAR_NOV_OCT,
    get label() {
      return Translate.instant(marker('TIME_SELECTION.DATE.DISCHARGE_YEAR_NOV_OCT'));
    }
  },
  [SmartTime.DISCHARGE_YEAR_OCT_SEPT]: {
    // In November and December, display the current period instead of the previous one.
    // We skip October to make sure that consumptions are calculated completely in October.
    get periodType() {
      return DateTime.local().month >= 11 ? CustomPeriod.CURRENT : CustomPeriod.PREVIOUS;
    },
    periodValue: 1,
    periodTime: CustomTime.YEARS,
    offsetValue: 9,
    offsetTime: CustomTime.MONTHS,
    value: SmartTime.DISCHARGE_YEAR_OCT_SEPT,
    get label() {
      return Translate.instant(marker('TIME_SELECTION.DATE.DISCHARGE_YEAR_OCT_SEPT'));
    }
  }
};

export const WidgetRoutingMap = {
  [WidgetType.ANCILLARY_COST_SETTLEMENT]: 'ancillaryCostSettlement',
  [WidgetType.BULLETIN_BOARD]: 'bulletinboard',
  [WidgetType.CARBON_FOOTPRINT_EMISSION_BREAKDOWN]: 'carbonFootprintEmissionBreakdown',
  [WidgetType.CARBON_FOOTPRINT_MEDIA_REPORTING]: 'carbonFootprintMediaReporting',
  [WidgetType.CARBON_FOOTPRINT_PERFORMANCE]: 'carbonFootprintPerformance',
  [WidgetType.CARBON_FOOTPRINT_REDUCTION_PATHWAY]: 'carbonFootprintReductionPathway',
  [WidgetType.CHART]: 'chart',
  [WidgetType.EXTERNAL_CONTENT]: 'externalContent',
  [WidgetType.FAQ]: 'faq',
  [WidgetType.IMAGE]: 'image',
  [WidgetType.MAP]: 'map',
  [WidgetType.METER_NETWORK]: 'meterNetwork',
  [WidgetType.REAL_ESTATE_DETAIL]: 'realestatedetail',
  [WidgetType.REPORTING]: 'reporting',
  [WidgetType.SCHEMATIC]: 'schematic',
  [WidgetType.SLA]: 'sla',
  [WidgetType.SLIDESHOW]: 'slideshow',
  [WidgetType.TABLE_REPORT]: 'tablereport',
  [WidgetType.TENANTS]: 'tenants',
  [WidgetType.TICKET_LIST]: 'ticketlist',
  [WidgetType.TRACKING]: 'tracking'
};

export const WidgetImageNameMap = {
  [ChartType.ALARM_SETUP_CATEGORY]: 'alarm_setup_category',
  [ChartType.CARPET]: 'carpet',
  [ChartType.DATAPOINT_CATEGORY]: 'datapoint_category',
  [ChartType.DURATION_CURVE]: 'duration_curve',
  [ChartType.GAUGE]: 'gauge',
  [ChartType.MEASUREMENT]: 'measurement',
  [ChartType.PIE]: 'pie',
  [ChartType.SCATTER]: 'scatter',
  [ChartType.TICKET_STATISTICS]: 'ticket_statistics',
  [ChartType.TIME_CATEGORY]: 'time_category',
  [WidgetType.ANCILLARY_COST_SETTLEMENT]: 'ancillaryCostSettlement',
  [WidgetType.BULLETIN_BOARD]: 'bulletinboard',
  [WidgetType.CARBON_FOOTPRINT_EMISSION_BREAKDOWN]: 'carbonFootprintEmissionBreakdown',
  [WidgetType.CARBON_FOOTPRINT_MEDIA_REPORTING]: 'carbonFootprintMediaReporting',
  [WidgetType.CARBON_FOOTPRINT_PERFORMANCE]: 'carbonFootprintPerformance',
  [WidgetType.CARBON_FOOTPRINT_REDUCTION_PATHWAY]: 'carbonFootprintReductionPathway',
  [WidgetType.EXTERNAL_CONTENT]: 'externalContent',
  [WidgetType.FAQ]: 'faq',
  [WidgetType.IMAGE]: 'image',
  [WidgetType.LAUNCH_PAD]: 'launchPad',
  [WidgetType.MAP]: 'map',
  [WidgetType.METER_NETWORK]: 'meterNetwork',
  [WidgetType.REAL_ESTATE_DETAIL]: 'realestatedetail',
  [WidgetType.REAL_ESTATE_LIST]: 'realestatelist',
  [WidgetType.REPORTING]: 'reporting',
  [WidgetType.SCHEMATIC]: 'schematic',
  [WidgetType.SLA]: 'sla',
  [WidgetType.SLIDESHOW]: 'slideshow',
  [WidgetType.TABLE_REPORT]: 'tablereport',
  [WidgetType.TENANTS]: 'tenants',
  [WidgetType.TICKET_LIST]: 'ticketlist',
  [WidgetType.TRACKING]: 'tracking'
};
