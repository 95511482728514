/* istanbul ignore file */
/* tslint:disable */

export enum ExcelCellStyle {
  NONE = 'NONE',
  NUMBER = 'NUMBER',
  DATE = 'DATE',
  DATE_TIME = 'DATE_TIME',
  BOOLEAN = 'BOOLEAN',
  FORMULA = 'FORMULA'
}
