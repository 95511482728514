/* istanbul ignore file */
/* tslint:disable */

export enum SmartTime {
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  LAST_7_DAYS = 'last_7_days',
  LAST_30_DAYS = 'last_30_days',
  LAST_365_DAYS = 'last_365_days',
  LAST_WEEK = 'last_week',
  LAST_MONTH = 'last_month',
  LAST_QUARTER = 'last_quarter',
  LAST_SEMESTER = 'last_semester',
  LAST_YEAR = 'last_year',
  THIS_WEEK = 'this_week',
  THIS_MONTH = 'this_month',
  THIS_QUARTER = 'this_quarter',
  THIS_SEMESTER = 'this_semester',
  THIS_YEAR = 'this_year',
  ANCILLARY_COST_SETTLEMENT_MAY_APR = 'ancillary_cost_settlement_may_apr',
  ANCILLARY_COST_SETTLEMENT_JUL_JUN = 'ancillary_cost_settlement_jul_jun',
  DISCHARGE_YEAR_OCT_SEPT = 'discharge_year_oct_sept',
  DISCHARGE_YEAR_NOV_OCT = 'discharge_year_nov_oct'
}
