const options: Intl.CollatorOptions = {
  numeric: true,
  sensitivity: 'accent'
};

interface CompareValue<T> {
  value: any;
  initialValue: T;
  index: number;
  type: string;
}

function localeSensitiveComparator<T>(v1: CompareValue<T>, v2: CompareValue<T>): number {
  let result = 0;
  const type1 = v1.type;
  const type2 = v2.type;

  if (type1 === type2) {
    let value1 = v1.value;
    let value2 = v2.value;

    if (type1 === 'string') {
      // Compare strings case-insensitively
      result = (value1 as string).localeCompare(value2 as string, undefined, options);
    } else {
      if (value1 !== null && type1 === 'object') {
        // For basic objects, use the position of the object
        // in the collection instead of the value
        value1 = v1.index;
        value2 = v2.index;
      }
      if (value1 !== value2) {
        result = value1 < value2 ? -1 : 1;
      }
    }
  } else {
    result = type1 < type2 ? -1 : 1;
  }

  return result;
}

type SortPredicate<T> = ((value: T) => any) | string;

/*
 * Orders an array by locale
 * Takes arguments: sortPredicate, reverseOrder.
 * Usage:
 *   orderByLocale(values, sortPredicate, reverseOrder)
 * Example:
 *   orderByLocale([{name: 'avd'}, {name: 'asd'}], 'name', false)
 *   orders to: [{name: 'asd'}, {name: 'avd'}]
 */
export function orderByLocale<T>(values: T[], sortPredicate: SortPredicate<T> | SortPredicate<T>[], reverseOrder = false): T[] {
  if (Array.isArray(values)) {
    const newValues: T[] = values
      .slice(0)
      .map((value: T, index: number) => {
        let sortPredicates: SortPredicate<T>[];
        const compareValues: CompareValue<T>[] = [];
        if (typeof sortPredicate === 'string' || typeof sortPredicate === 'function') {
          sortPredicates = [sortPredicate];
        } else {
          sortPredicates = sortPredicate;
        }

        for (const predicate of sortPredicates) {
          const compareValue = typeof predicate === 'function' ? predicate(value) : value[predicate];

          compareValues.push({
            value: compareValue,
            initialValue: value,
            index,
            type: typeof compareValue
          });
        }

        return compareValues;
      })
      .sort((value1, value2) => {
        let result = 0;
        for (let i = 0; i < value1.length; i++) {
          result = localeSensitiveComparator(value1[i], value2[i]);
          if (result !== 0) {
            break;
          }
        }
        return result;
      })
      .map(value => value[0].initialValue);
    if (reverseOrder) {
      return newValues.reverse();
    } else {
      return newValues;
    }
  } else {
    return values;
  }
}
