/* istanbul ignore file */
/* tslint:disable */

export enum GroupAttributeType {
  BASE_TEMPERATURE = 'BASE_TEMPERATURE',
  ENERGY_AREA = 'ENERGY_AREA',
  DOMESTIC_HOT_WATER = 'DOMESTIC_HOT_WATER',
  SPECIFIC_EMISSION = 'SPECIFIC_EMISSION',
  SPECIFIC_ENERGY = 'SPECIFIC_ENERGY'
}
