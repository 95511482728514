/* istanbul ignore file */
/* tslint:disable */

export enum WidgetType {
  CHART = 'chart',
  METER_NETWORK = 'meterNetwork',
  IMAGE = 'image',
  MAP = 'map',
  TICKET_LIST = 'ticketList',
  LAUNCH_PAD = 'launchPad',
  REPORTING = 'reporting',
  SCHEMATIC = 'schematic',
  SLIDESHOW = 'slideshow',
  TABLE_REPORT = 'tableReport',
  REAL_ESTATE_LIST = 'realEstateList',
  REAL_ESTATE_DETAIL = 'realEstateDetail',
  BULLETIN_BOARD = 'bulletinBoard',
  TENANTS = 'tenants',
  ANCILLARY_COST_SETTLEMENT = 'ancillaryCostSettlement',
  FAQ = 'faq',
  SLA = 'sla',
  TRACKING = 'tracking',
  EXTERNAL_CONTENT = 'externalContent',
  CARBON_FOOTPRINT_PERFORMANCE = 'carbonFootprintPerformance',
  CARBON_FOOTPRINT_REDUCTION_PATHWAY = 'carbonFootprintReductionPathway',
  CARBON_FOOTPRINT_MEDIA_REPORTING = 'carbonFootprintMediaReporting',
  CARBON_FOOTPRINT_EMISSION_BREAKDOWN = 'carbonFootprintEmissionBreakdown'
}
