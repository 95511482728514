/* istanbul ignore file */
/* tslint:disable */

export enum GroupDType {
  MASTER_PAGE_GROUP = 'MasterPageGroup',
  BUILDING = 'Building',
  REAL_ESTATE = 'RealEstate',
  STRUCTURE_GROUP = 'StructureGroup',
  PORTFOLIO = 'Portfolio'
}
