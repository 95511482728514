import {Injectable} from '@angular/core';
import {UserLanguages} from '../../shared/properties/user/user-languages.enum';
import {UserLocalesLabels} from '../../shared/properties/user/user-locales-labels.constant';

@Injectable({providedIn: 'root'})
export class UserLocaleService {
  static getCountry(locale: string): string {
    return locale.split('-')[1];
  }

  static getLanguage(locale: string): string {
    const language = locale.split('-')[0];
    return Object.values(UserLanguages).includes(language as UserLanguages) ? language : UserLanguages.ENGLISH;
  }

  static detectUserLocale(locale: string): string {
    // Find supported locale based on language or locale.
    if (UserLocalesLabels[locale]) {
      return locale;
    }

    // Find supported locale based on language if the given locale isn't supported.
    const localeKeys = Object.keys(UserLocalesLabels);
    const language = UserLocaleService.getLanguage(locale);
    for (const localeKey of localeKeys) {
      if (localeKey.indexOf(language) !== -1) {
        return localeKey;
      }
    }

    return null;
  }
}
