import {Injectable} from '@angular/core';
import {Params} from '@angular/router';
import {EventTypes, OidcSecurityService, PublicEventsService} from 'angular-auth-oidc-client';
import {ClientIdAndNameTO} from 'api/entities';
import {Settings} from 'luxon';
import {NgxPermissionsService, NgxRolesService} from 'ngx-permissions';
import {forkJoin, NEVER, Observable, of, retry, throwError} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {lang} from '../../product-information.constant';
import {LocalStorageKeys} from '../../shared/constants/local-storage-keys.constant';

import {UserSessionService} from '../../shared/entities/auth/user-session.service';
import {CurrentUser} from '../../shared/entities/user/avelon-user.interface';
import {Alcedo7User} from '../../shared/entities/user/avelon-user.service';
import {CurrentUserEntity} from '../../shared/entities/user/current-user.entity';
import {UserPreferencesEntity, UserPreferencesI} from '../../shared/entities/user/user-preferences.entity';
import {setBrandingThemeColors} from '../../shared/services/theming.static';
import {Translate} from '../../shared/services/translate.service';
import {ACL_ROLES} from './auth-control-definitions.constant';
import {UserLocaleService} from './user-locale.service';

@Injectable({providedIn: 'root'})
export class AuthService {
  private currentClientLoaded: number = null;

  constructor(
    private permissionsService: NgxPermissionsService,
    private rolesService: NgxRolesService,
    private currentUserEntity: CurrentUserEntity,
    private userPreferencesEntity: UserPreferencesEntity,
    private oidcSecurityService: OidcSecurityService,
    private eventService: PublicEventsService
  ) {
    window.addEventListener('storage', (event: StorageEvent) => this.syncTabs(event));

    this.eventService.registerForEvents().subscribe(notification => {
      if (notification.type === EventTypes.UserDataChanged) {
        if (notification.value.userData) {
          UserSessionService.setSessionDummy();
        } else if (!UserSessionService.isSlideShowOrReporting()) {
          UserSessionService.removeSessionDummy();
        }
      }
    });
  }

  syncTabs(event: StorageEvent): void {
    if (UserSessionService.isSlideShowOrReporting()) {
      return;
    }
    const isLanguageChange = event.key === LocalStorageKeys.UserSession.languageChangeKey;
    if (isLanguageChange) {
      this.oidcSecurityService.forceRefreshSession().subscribe(() => location.reload());
    }
    const isAccessToken = event.key === LocalStorageKeys.UserSession.sessionDummyKey;
    const isLogin = isAccessToken && !event.oldValue && event.newValue;
    const isLogout = isAccessToken && event.oldValue && !event.newValue;
    if (isLogout && Alcedo7User.currentUser) {
      UserSessionService.storeRef();
      UserSessionService.clearUserFromMemory();
      this.oidcSecurityService.logoff().subscribe();
    } else if (isLogin) {
      this.oidcSecurityService.authorize();
    }
  }

  private loadCurrentUser(stateParams: Params): Observable<any> {
    return forkJoin([
      this.userPreferencesEntity.load(),
      this.currentUserEntity.getCurrentUser(stateParams),
      this.currentUserEntity.getClients()
    ]).pipe(
      switchMap(([userPreferences, currentUser, clients]: [UserPreferencesI, CurrentUser, ClientIdAndNameTO[]]) => {
        // Unused but needs to be loaded at the same time with current user, to be available in the application
        return this.setUser(currentUser, stateParams && stateParams.clientId ? +stateParams.clientId : currentUser.clientId);
      }),
      catchError(err => {
        return this.oidcSecurityService.isAuthenticated().pipe(
          switchMap(isAuthenticated => {
            if (isAuthenticated) {
              return throwError(err);
            } else {
              this.oidcSecurityService.logoffLocal();
              UserSessionService.destroy();
              return NEVER;
            }
          })
        );
      }),
      retry({count: 1000, delay: 2000}),
      catchError(err => {
        console.error(err);
        this.oidcSecurityService.logoffLocal();
        UserSessionService.destroy();
        return NEVER;
      })
    );
  }

  private setUser(user: CurrentUser, selectedClientId: number): Observable<any> {
    if (selectedClientId) {
      this.currentClientLoaded = selectedClientId;
    } else {
      this.currentClientLoaded = user.clientId;
    }
    this.setUserRights(user.userRole.actions, user.root);
    setBrandingThemeColors();
    return this.setLocale(user);
  }

  private setUserRights(userRights, isRoot: boolean): void {
    this.permissionsService.loadPermissions(userRights);
    if (isRoot) {
      this.rolesService.addRole(ACL_ROLES.ROOT, () => Alcedo7User.currentUser.root);
    }
  }

  setLocale(user: CurrentUser): Observable<any> {
    lang.locale = user.language;
    Settings.defaultLocale = user.language;
    const language = UserLocaleService.getLanguage(user.language);
    Translate.getInstance().setActiveLang(language);
    return Translate.getInstance().load(language);
  }

  getPromise(stateParams: Params): Observable<boolean> {
    let loadingClient;
    if (!Alcedo7User.currentUser) {
      loadingClient = this.loadCurrentUser(stateParams);
    } else if (stateParams.clientId && this.currentClientLoaded !== +stateParams.clientId) {
      loadingClient = this.loadCurrentUser(stateParams);
    } else {
      loadingClient = of(Alcedo7User.currentUser);
    }
    return loadingClient.pipe(map((isAuth: boolean): boolean => !!isAuth));
    // TODO what happens if MW is down, keycloak up
    // TODO what happens if MW and keycloak is down
  }
}
