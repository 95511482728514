import {NgIf} from '@angular/common';
import {Component} from '@angular/core';
import {AvelonCloudHost} from '../../../shared/services/brand.service';

@Component({
  selector: 'alc-splash-screen',
  standalone: true,
  templateUrl: './splash-screen.component.html',
  imports: [NgIf],
  styleUrls: ['./splash-screen.component.scss']
})
export class SplashScreenComponent {
  isPoweredBy = window.location.hostname !== AvelonCloudHost;
}
