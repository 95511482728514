import {Provider} from '@angular/core';
import {LuxonDateAdapter, MAT_LUXON_DATE_ADAPTER_OPTIONS, MAT_LUXON_DATE_FORMATS} from '@angular/material-luxon-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

export const LUXON_ADAPTER_PROVIDERS: Provider[] = [
  {
    provide: DateAdapter,
    useClass: LuxonDateAdapter,
    deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS]
  },
  {
    provide: MAT_DATE_FORMATS,
    useValue: MAT_LUXON_DATE_FORMATS
  }
];

export const LUXON_ADAPTER_UTC_PROVIDERS: Provider[] = [
  {
    provide: MAT_LUXON_DATE_ADAPTER_OPTIONS,
    useValue: {useUtc: true}
  },
  ...LUXON_ADAPTER_PROVIDERS
];


