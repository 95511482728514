import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {DeviceTypes} from '../../properties/device-types.enum';
import {Alcedo7User} from '../user/avelon-user.service';
import {BaseDevice, FullDevice} from './device.interface';

export const DEVICE_TYPES = {
  [DeviceTypes.COMTAC_DI]: {
    model: DeviceTypes.COMTAC_DI,
    image: 'unknown.svg',
    route: 'sensor'
  },
  [DeviceTypes.COMTAC_LD_1]: {
    model: DeviceTypes.COMTAC_LD_1,
    image: 'comtac-ld1.png',
    route: 'sensor'
  },
  [DeviceTypes.CITSL]: {
    model: DeviceTypes.CITSL,
    image: 'unknown.svg',
    route: 'legacy'
  },
  [DeviceTypes.CITXT]: {
    model: DeviceTypes.CITXT,
    image: 'unknown.svg',
    route: 'legacy'
  },
  [DeviceTypes.CITXTLAN]: {
    model: DeviceTypes.CITXTLAN,
    image: 'unknown.svg',
    route: 'legacy'
  },
  [DeviceTypes.DVR]: {
    model: DeviceTypes.DVR,
    image: 'unknown.svg',
    route: 'legacy'
  },
  [DeviceTypes.GENERIC_IOT]: {
    model: DeviceTypes.GENERIC_IOT,
    image: 'iot.svg',
    route: 'sensor'
  },
  [DeviceTypes.IOT_BUTTON]: {
    model: DeviceTypes.IOT_BUTTON,
    image: 'button.svg',
    route: 'sensor'
  },
  [DeviceTypes.IOT_TRACY]: {
    model: DeviceTypes.IOT_TRACY,
    image: 'tracy.png',
    route: 'sensor'
  },
  [DeviceTypes.IOT_TRACY2]: {
    model: DeviceTypes.IOT_TRACY2,
    image: 'tracy.png',
    route: 'sensor'
  },
  [DeviceTypes.IOT_GATEWAY]: {
    model: DeviceTypes.IOT_GATEWAY,
    image: 'gateway.svg',
    route: 'sensor'
  },
  [DeviceTypes.IOT_WATER_LEAK_DETECTOR]: {
    model: DeviceTypes.IOT_WATER_LEAK_DETECTOR,
    image: 'leaksense.png',
    iotLicenseSize: 5,
    route: 'sensor'
  },
  [DeviceTypes.IOT_THERMOSENSE]: {
    model: DeviceTypes.IOT_THERMOSENSE,
    image: 'thermosense.png',
    iotLicenseSize: 5,
    route: 'sensor'
  },
  [DeviceTypes.IOT_THERMOSENSE_ONE_WIRE]: {
    model: DeviceTypes.IOT_THERMOSENSE_ONE_WIRE,
    image: 'thermosense.png',
    iotLicenseSize: 5,
    route: 'sensor'
  },
  [DeviceTypes.IOT_THERMOSENSE_OUTDOOR]: {
    model: DeviceTypes.IOT_THERMOSENSE_OUTDOOR,
    image: 'thermosense.png',
    iotLicenseSize: 2,
    route: 'sensor'
  },
  [DeviceTypes.IOT_THERMOSENSE_FRIGO]: {
    model: DeviceTypes.IOT_THERMOSENSE_FRIGO,
    image: 'thermosense.png',
    iotLicenseSize: 2,
    route: 'sensor'
  },
  [DeviceTypes.IOT_WATER_METER]: {
    model: DeviceTypes.IOT_WATER_METER,
    image: 'gwf-water-meter.png',
    route: 'sensor'
  },
  [DeviceTypes.IOT_WATTECO_IN_O]: {
    model: DeviceTypes.IOT_WATTECO_IN_O,
    image: 'binary-10-4.png',
    iotLicenseSize: 10,
    route: 'sensor'
  },
  [DeviceTypes.IOTQUALITYSENSOR]: {
    model: DeviceTypes.IOTQUALITYSENSOR,
    image: 'decentlab-co2.png',
    route: 'sensor'
  },
  [DeviceTypes.IOT_THERMO_VALVE]: {
    model: DeviceTypes.IOT_THERMO_VALVE,
    image: 'thermo-valve.png',
    route: 'sensor'
  },
  [DeviceTypes.SCADA]: {
    model: DeviceTypes.SCADA,
    image: 'beetle-docker.svg',
    route: 'beetle'
  },
  [DeviceTypes.SLAMI]: {
    model: DeviceTypes.SLAMI,
    image: 'unknown.svg',
    route: 'legacy'
  },
  [DeviceTypes.T3]: {
    model: DeviceTypes.T3,
    image: 'beetle-t3.png',
    route: 'beetle'
  },
  [DeviceTypes.T4]: {
    model: DeviceTypes.T4,
    image: 'beetle-t4.png',
    route: 'beetle'
  },
  [DeviceTypes.T5]: {
    model: DeviceTypes.T5,
    image: 'beetle-t5.png',
    route: 'beetle'
  },
  [DeviceTypes.T6]: {
    model: DeviceTypes.T6,
    image: 'beetle-t6.png',
    route: 'beetle'
  },
  [DeviceTypes.WAGO_750_MQTT]: {
    model: DeviceTypes.WAGO_750_MQTT,
    image: 'wago-750-8212.png',
    route: 'wago'
  },
  [DeviceTypes.TABLET]: {
    model: DeviceTypes.TABLET,
    image: 'tablet.png',
    route: 'tablet'
  },
  [DeviceTypes.IOT_TEK_766]: {
    model: DeviceTypes.IOT_TEK_766,
    image: 'tekelek-tek766.png',
    route: 'sensor'
  },
  [DeviceTypes.MULTICAL]: {
    model: DeviceTypes.MULTICAL,
    image: 'multical.png',
    route: 'sensor'
  },
  [DeviceTypes.IOT_BOSCH_TPS_110]: {
    model: DeviceTypes.IOT_BOSCH_TPS_110,
    image: 'bosch-tps-110.png',
    route: 'sensor'
  },
  [DeviceTypes.UNKNOWN]: {
    model: DeviceTypes.UNKNOWN,
    image: 'unknown.svg',
    route: 'legacy'
  },
  [DeviceTypes.VIRT]: {
    model: DeviceTypes.VIRT,
    image: 'beetle-vm.svg',
    route: 'beetle'
  },
  [DeviceTypes.WEATHER]: {
    model: DeviceTypes.WEATHER,
    image: 'weather.svg',
    route: 'weather'
  },
  [DeviceTypes.WISELY]: {
    model: DeviceTypes.WISELY,
    image: 'wisely.png',
    iotLicenseSize: 2,
    route: 'sensor'
  },
  [DeviceTypes.WISELY_ALLSENSE]: {
    model: DeviceTypes.WISELY_ALLSENSE,
    image: 'wisely.png',
    iotLicenseSize: 10,
    route: 'sensor'
  },
  [DeviceTypes.WISELY_CARBONSENSE]: {
    model: DeviceTypes.WISELY_CARBONSENSE,
    image: 'wisely.png',
    iotLicenseSize: 5,
    route: 'sensor'
  },
  [DeviceTypes.TRACKER_FMC001]: {
    model: DeviceTypes.TRACKER_FMC001,
    image: 'fmc001.png',
    route: 'tracker'
  },
  [DeviceTypes.TRACKER_FMC640]: {
    model: DeviceTypes.TRACKER_FMC640,
    image: 'fmc640.png',
    route: 'tracker'
  },
  [DeviceTypes.VIRTUAL_DEVICE]: {
    model: DeviceTypes.VIRTUAL_DEVICE,
    image: 'unknown.svg',
    route: 'virtual'
  },
  [DeviceTypes.ON_SITE_PANEL_SERVER]: {
    model: DeviceTypes.ON_SITE_PANEL_SERVER,
    image: 'on-site-panel-server.svg',
    route: 'onSitePanelServer'
  }
};

@Injectable({providedIn: 'root'})
export class DeviceService {
  constructor(private router: Router) {}

  static BEETLE_HARDWARE_MODELS = [DEVICE_TYPES.T3.model, DEVICE_TYPES.T4.model, DEVICE_TYPES.T5.model, DEVICE_TYPES.T6.model];
  static BEETLE_MODELS = [
    DEVICE_TYPES.T3.model,
    DEVICE_TYPES.T4.model,
    DEVICE_TYPES.T5.model,
    DEVICE_TYPES.T6.model,
    DEVICE_TYPES.SCADA.model,
    DEVICE_TYPES.VIRT.model
  ];
  static BEETLE_OPC_MODELS = [DEVICE_TYPES.T5.model, DEVICE_TYPES.T6.model, DEVICE_TYPES.SCADA.model];
  static BEETLE_LORA_MODELS = [DEVICE_TYPES.T4.model, DEVICE_TYPES.T5.model, DEVICE_TYPES.T6.model, DEVICE_TYPES.VIRT.model];
  static IOT_MODELS = [
    DEVICE_TYPES.COMTAC_DI.model,
    DEVICE_TYPES.COMTAC_LD_1.model,
    DEVICE_TYPES.IOT_TRACY.model,
    DEVICE_TYPES.IOT_TRACY2.model,
    DEVICE_TYPES.GENERIC_IOT.model,
    DEVICE_TYPES.IOT_BUTTON.model,
    DEVICE_TYPES.IOT_WATER_LEAK_DETECTOR.model,
    DEVICE_TYPES.IOT_TEK_766.model,
    DEVICE_TYPES.MULTICAL.model,
    DEVICE_TYPES.IOT_BOSCH_TPS_110.model,
    DEVICE_TYPES.IOT_THERMOSENSE.model,
    DEVICE_TYPES.IOT_THERMOSENSE_ONE_WIRE.model,
    DEVICE_TYPES.IOT_THERMOSENSE_OUTDOOR.model,
    DEVICE_TYPES.IOT_THERMOSENSE_FRIGO.model,
    DEVICE_TYPES.IOT_WATER_METER.model,
    DEVICE_TYPES.IOT_WATTECO_IN_O.model,
    DEVICE_TYPES.IOTQUALITYSENSOR.model,
    DEVICE_TYPES.WISELY.model,
    DEVICE_TYPES.WISELY_ALLSENSE.model,
    DEVICE_TYPES.WISELY_CARBONSENSE.model,
    DEVICE_TYPES.IOT_THERMO_VALVE.model
  ];

  static IOT_WISELY_MODELS = [DEVICE_TYPES.WISELY.model, DEVICE_TYPES.WISELY_ALLSENSE.model, DEVICE_TYPES.WISELY_CARBONSENSE.model];
  static TRACKERS_MODELS = [DEVICE_TYPES.TRACKER_FMC001.model, DEVICE_TYPES.TRACKER_FMC640.model];
  static WAGO_MODELS = [DEVICE_TYPES.WAGO_750_MQTT.model];
  static LEGACY_MODELS = [
    DEVICE_TYPES.CITSL.model,
    DEVICE_TYPES.CITXT.model,
    DEVICE_TYPES.CITXTLAN.model,
    DEVICE_TYPES.SLAMI.model,
    DEVICE_TYPES.DVR.model,
    DEVICE_TYPES.UNKNOWN.model
  ];
  static VIRTUAL_DEVICE_MODELS = [DEVICE_TYPES.VIRTUAL_DEVICE.model];
  static ON_SITE_PANEL_SERVER_MODELS = [DEVICE_TYPES.ON_SITE_PANEL_SERVER.model];

  static getDeviceIcon(model: string): string {
    const modelEntry = DEVICE_TYPES[model] || DEVICE_TYPES.UNKNOWN;
    return 'assets/images/devices/' + modelEntry.image;
  }

  static hasLabel(input: BaseDevice): boolean {
    return (
      !DeviceService.isThermoValve(input) &&
      (DeviceService.isBeetleHardware(input) ||
        DeviceService.isIoTDevice(input) ||
        DeviceService.isIoTGateway(input) ||
        DeviceService.isTracker(input))
    );
  }

  static hasQRCode(input: BaseDevice): boolean {
    return (
      DeviceService.isIoTDevice(input) || DeviceService.isIoTGateway(input) || DeviceService.isTracker(input) || DeviceService.isWAGODevice(input)
    );
  }

  static isBeetle(input: string | Partial<BaseDevice>): boolean {
    const model = typeof input === 'string' ? input : input.model;
    return DeviceService.BEETLE_MODELS.indexOf(model as any) > -1;
  }

  static isWAGODevice(input: string | Partial<BaseDevice>): boolean {
    const model = typeof input === 'string' ? input : input.model;
    return DeviceService.WAGO_MODELS.indexOf(model as any) > -1;
  }

  static isTracker(input: string | Partial<BaseDevice>): boolean {
    const model = typeof input === 'string' ? input : input.model;
    return DeviceService.TRACKERS_MODELS.indexOf(model as any) > -1;
  }

  static isVirtualDevice(input: string | Partial<BaseDevice>): boolean {
    const model = typeof input === 'string' ? input : input.model;
    return DeviceService.VIRTUAL_DEVICE_MODELS.indexOf(model as any) > -1;
  }

  static isOnSitePanelServer(input: string | Partial<BaseDevice>): boolean {
    const model = typeof input === 'string' ? input : input.model;
    return DeviceService.ON_SITE_PANEL_SERVER_MODELS.indexOf(model as any) > -1;
  }

  static isBeetleHardware(input: string | BaseDevice): boolean {
    const model = typeof input === 'string' ? input : input.model;
    return DeviceService.BEETLE_HARDWARE_MODELS.indexOf(model as any) > -1;
  }

  static isBeetleOPC(input: string | BaseDevice): boolean {
    const model = typeof input === 'string' ? input : input.model;
    return DeviceService.BEETLE_OPC_MODELS.indexOf(model as any) > -1;
  }

  static isBinary104(input: BaseDevice): boolean {
    return input.model === DEVICE_TYPES.IOT_WATTECO_IN_O.model;
  }

  static isIoTDevice(input: string | Partial<BaseDevice>): boolean {
    const model = typeof input === 'string' ? input : input.model;
    return DeviceService.IOT_MODELS.indexOf(model as any) > -1;
  }

  static isIoTButton(input: Partial<BaseDevice>): boolean {
    return input.model === DEVICE_TYPES.IOT_BUTTON.model;
  }

  static isIoTGateway(input: Partial<BaseDevice>): boolean {
    return input.model === DEVICE_TYPES.IOT_GATEWAY.model;
  }

  static isIoTGeneric(input: Partial<BaseDevice>): boolean {
    return input.model === DEVICE_TYPES.GENERIC_IOT.model;
  }

  static isComtacLD(input: Partial<BaseDevice>): boolean {
    return input.model === DEVICE_TYPES.COMTAC_LD_1.model;
  }

  static isIotTek766(input: Partial<BaseDevice>): boolean {
    return input.model === DEVICE_TYPES.IOT_TEK_766.model;
  }

  static isMultical(input: Partial<BaseDevice>): boolean {
    return input.model === DEVICE_TYPES.MULTICAL.model;
  }

  static isIoTBoschTPS110(input: Partial<BaseDevice>): boolean {
    return input.model === DEVICE_TYPES.IOT_BOSCH_TPS_110.model;
  }

  static isThermoValve(input: Partial<BaseDevice>): boolean {
    return input.model === DEVICE_TYPES.IOT_THERMO_VALVE.model;
  }

  static isLoRaWANCapableBeetle(input: string | Partial<BaseDevice>): boolean {
    const model = typeof input === 'string' ? input : input.model;
    return DeviceService.BEETLE_LORA_MODELS.indexOf(model as any) > -1;
  }

  static isWisely(input: Partial<BaseDevice>): boolean {
    return DeviceService.IOT_WISELY_MODELS.indexOf(input.model as any) > -1;
  }

  static isWiselyV3(input: FullDevice): boolean {
    // Check if given version is >= 3.1
    const version = (input.softwareVersion || '').split('.');
    return !(version.length < 2 || parseInt(version[0], 10) < 3 || parseInt(version[1], 10) < 1);
  }

  static isWiselyAllSense(device: Partial<BaseDevice>): boolean {
    return device.model === DEVICE_TYPES.WISELY_ALLSENSE.model;
  }

  static isThermoSense(input: Partial<BaseDevice>): boolean {
    return (
      input.model === DEVICE_TYPES.IOT_THERMOSENSE.model ||
      input.model === DEVICE_TYPES.IOT_THERMOSENSE_ONE_WIRE.model ||
      input.model === DEVICE_TYPES.IOT_THERMOSENSE_OUTDOOR.model ||
      input.model === DEVICE_TYPES.IOT_THERMOSENSE_FRIGO.model
    );
  }

  static isTracy(input: Partial<BaseDevice>): boolean {
    return input.model === DEVICE_TYPES.IOT_TRACY.model;
  }

  static isTracy2(input: Partial<BaseDevice>): boolean {
    return input.model === DEVICE_TYPES.IOT_TRACY2.model;
  }

  static isWaterLeakDetector(input: Partial<BaseDevice>): boolean {
    return input.model === DEVICE_TYPES.IOT_WATER_LEAK_DETECTOR.model;
  }

  static isWatteco(input: Partial<BaseDevice>): boolean {
    return input.model === DEVICE_TYPES.IOT_WATTECO_IN_O.model;
  }

  static isWeatherDevice(input: Partial<BaseDevice>): boolean {
    return input.model === DEVICE_TYPES.WEATHER.model;
  }

  static isLegacy(input: Partial<BaseDevice>): boolean {
    return DeviceService.LEGACY_MODELS.indexOf(input.model as any) > -1;
  }

  static isTablet(input: Partial<BaseDevice>): boolean {
    return input.model === DEVICE_TYPES.TABLET.model;
  }

  goToDevice(device: BaseDevice, newTab?: boolean) {
    const stateParams = {
      deviceId: device.id,
      clientId: device.clientId || (Alcedo7User.selectedClient ? Alcedo7User.selectedClient.id : Alcedo7User.currentUser.clientId),
      deviceType: DEVICE_TYPES[device.model].route
    };
    const route = ['client', stateParams.clientId, 'devices', stateParams.deviceType, stateParams.deviceId, 'configuration'];
    if (newTab) {
      window.open(location.origin + '/' + route.join('/'), '_blank', 'noreferrer');
    } else {
      this.router.navigate(route);
    }
  }
}
