import {HttpErrorResponse} from '@angular/common/http';
import {MatDialog} from '@angular/material/dialog';
import {marker} from '@ngneat/transloco-keys-manager/marker';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {ToastrService} from 'ngx-toastr';
import {take} from 'rxjs/operators';
import {UserSessionService} from '../../shared/entities/auth/user-session.service';
import {Alcedo7User} from '../../shared/entities/user/avelon-user.service';
import {StatusCheckService} from '../../shared/services/status-check.service';
import {Translate} from '../../shared/services/translate.service';
import {ExceededLicenseDataInterface} from '../license/exceeded-license-entries.interface';
import {LimitLicenseComponent} from '../license/limit-license.component';

export class ErrorInterceptorHandle {
  constructor(
    private toastrService: ToastrService,
    private statusCheck: StatusCheckService,
    private oidcSecurityService: OidcSecurityService,
    private dialog: MatDialog
  ) {}

  private static getToastErrorMessage(response: HttpErrorResponse, data?: any): string {
    let toastMessage: string;
    if (data?.message) {
      toastMessage = data.message || data.status;
    } else if (response.error) {
      toastMessage = response.error.message || response.error.status || response.message;
    } else {
      toastMessage = response.message;
    }
    return toastMessage;
  }

  handleErrors(err: any): void {
    if (err instanceof HttpErrorResponse) {
      this.checkErrorCurrentUser(err);
      if (err.status > 0 && !err.url.includes('errorlog')) {
        this.statusCheck.checkSystemState();

        if (err.error && err.error instanceof Blob) {
          this.handleResponseTypeBlob(err);
        } else {
          this.handleErrorMessage(err);
        }
      }
    }
  }

  private checkErrorCurrentUser(err: HttpErrorResponse): void {
    if ((err.status === 401 || err.status === 404) && err.url.indexOf('users/current') > -1) {
      UserSessionService.destroy();
      this.oidcSecurityService.logoff().subscribe();
    }
  }

  private handleResponseTypeBlob(response: HttpErrorResponse): void {
    const self = this;
    const file = new FileReader();
    file.readAsText(response.error);
    file.onload = () => {
      try {
        self.handleErrorMessage(response, JSON.parse(file.result as string));
      } catch (e) {
        self.handleErrorMessage(response);
      }
    };
  }

  private handleErrorMessage(response: HttpErrorResponse, data?: any): void {
    if (StatusCheckService.isOffline() || UserSessionService.isReporting) {
      return;
    }

    const toastMessage = ErrorInterceptorHandle.getToastErrorMessage(response, data);
    let errorTitle: string = marker('GENERAL.TITLE.ERROR');
    let exceededPackagesMessage: string = marker('LICENSE.MESSAGE.SEE_EXCEEDED_PACKAGES');
    let exceededLicensesTitle: string = marker('LICENSE.TITLE.LICENSES_EXCEEDED');

    Translate.get([errorTitle, exceededPackagesMessage, exceededLicensesTitle])
      .pipe(take(1))
      .subscribe({
        next: (translations: string[]) => {
          [errorTitle, exceededPackagesMessage, exceededLicensesTitle] = translations;
          if (response.error && response.error.code === 40202) {
            const license = [response.error.properties.EXCEEDED_LICENSE_INFO];
            const body = toastMessage + '\n\n' + exceededPackagesMessage;
            this.closeDuplicateToast(body);
            this.toastrService
              .warning(body, exceededLicensesTitle, {disableTimeOut: !Alcedo7User.currentUser.root})
              .onTap.subscribe(() => this.showLicenseLimitDialog(license));
          } else {
            this.closeDuplicateToast(toastMessage);
            this.toastrService.error(toastMessage, errorTitle);
          }
        },
        error: err => console.error(err)
      });
  }

  private closeDuplicateToast(message: string) {
    if (this.toastrService.currentlyActive) {
      this.toastrService.toasts.filter(t => t.message === message).forEach(t => this.toastrService.remove(t.toastId));
    }
  }

  private showLicenseLimitDialog(exceededLicenseData: ExceededLicenseDataInterface[]) {
    this.dialog.open(LimitLicenseComponent, {
      width: '700px',
      data: {
        exceededLicenseData
      }
    });
  }
}
