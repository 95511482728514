/* istanbul ignore file */
/* tslint:disable */

export enum DeviceType {
  T3 = 'T3',
  T4 = 'T4',
  T5 = 'T5',
  T6 = 'T6',
  SCADA = 'SCADA',
  UNKNOWN = 'UNKNOWN',
  VIRT = 'VIRT',
  VIRTUAL_DEVICE = 'VIRTUAL_DEVICE',
  CITSL = 'CITSL',
  CITXT = 'CITXT',
  CITXTLAN = 'CITXTLAN',
  DVR = 'DVR',
  SLAMI = 'SLAMI',
  TABLET = 'TABLET',
  ON_SITE_PANEL_SERVER = 'ON_SITE_PANEL_SERVER',
  WISELY = 'WISELY',
  WISELY_ALLSENSE = 'WISELY_ALLSENSE',
  WISELY_CARBONSENSE = 'WISELY_CARBONSENSE',
  IOTQUALITYSENSOR = 'IOTQUALITYSENSOR',
  IOT_WATER_METER = 'IOT_WATER_METER',
  IOT_WATER_LEAK_DETECTOR = 'IOT_WATER_LEAK_DETECTOR',
  IOT_BUTTON = 'IOT_BUTTON',
  COMTAC_DI = 'COMTAC_DI',
  COMTAC_LD_1 = 'COMTAC_LD_1',
  WEATHER = 'WEATHER',
  GENERIC_IOT = 'GENERIC_IOT',
  IOT_WATTECO_IN_O = 'IOT_WATTECO_IN_O',
  IOT_GATEWAY = 'IOT_GATEWAY',
  IOT_TRACY = 'IOT_TRACY',
  IOT_TRACY2 = 'IOT_TRACY2',
  IOT_THERMOSENSE = 'IOT_THERMOSENSE',
  IOT_THERMOSENSE_ONE_WIRE = 'IOT_THERMOSENSE_ONE_WIRE',
  IOT_THERMOSENSE_OUTDOOR = 'IOT_THERMOSENSE_OUTDOOR',
  IOT_THERMOSENSE_FRIGO = 'IOT_THERMOSENSE_FRIGO',
  IOT_TEK_766 = 'IOT_TEK_766',
  MULTICAL = 'MULTICAL',
  IOT_BOSCH_TPS_110 = 'IOT_BOSCH_TPS_110',
  TRACKER_FMC001 = 'TRACKER_FMC001',
  TRACKER_FMC640 = 'TRACKER_FMC640',
  IOT_THERMO_VALVE = 'IOT_THERMO_VALVE',
  WAGO_750_MQTT = 'WAGO_750_MQTT'
}
