import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {ClientIdAndNameTO} from 'api/entities';
import {forkJoin} from 'rxjs';
import {take} from 'rxjs/operators';
import {UserSessionService} from '../../shared/entities/auth/user-session.service';
import {CurrentUser} from '../../shared/entities/user/avelon-user.interface';
import {CurrentUserEntity} from '../../shared/entities/user/current-user.entity';
import {SplashScreenComponent} from './splash-screen/splash-screen.component';

@Component({
  selector: 'alc-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
  standalone: true,
  imports: [SplashScreenComponent]
})
export class LandingPageComponent implements OnInit {
  constructor(private oidcSecurityService: OidcSecurityService, private router: Router, private currentUserEntity: CurrentUserEntity) {}

  ngOnInit() {
    this.oidcSecurityService
      .isAuthenticated()
      .pipe(take(1))
      .subscribe({
        next: isAuthenticated => {
          if (isAuthenticated) {
            this.navigateUser();
          } else {
            this.oidcSecurityService.authorize();
          }
        },
        error: () => {
          if (location.search.startsWith('?state=')) {
            // ERROR could not find matching config for state
            // this happens when resetting password and user opens email link in a new browser tab
            // the oidc library should authorize() again instead of throwing an error
            this.oidcSecurityService.authorize();
          }
        }
      });
  }

  navigateUser(): void {
    forkJoin([this.currentUserEntity.getClients(), this.currentUserEntity.getCurrentUserDefault()]).subscribe(
      ([clients, currentUser]: [ClientIdAndNameTO[], CurrentUser]) => {
        const ref = UserSessionService.getRef();
        const refSplit = ref?.split('/') || [];
        const isClientRef = refSplit[1] === 'client';
        const refClientId = +refSplit[2];
        const hasClientAccess = isClientRef && clients.find(c => c.id === refClientId);
        if (ref && hasClientAccess) {
          location.href = location.origin + ref;
        } else {
          this.router.navigate(['client', currentUser.clientId, 'dashboards'], {state: {showNavigation: true}});
        }
      }
    );
  }
}
