/* istanbul ignore file */
/* tslint:disable */

export enum LogType {
  DATA_POINT = 'DATA_POINT',
  USER = 'USER',
  ALARM_CHAIN = 'ALARM_CHAIN',
  LOGIN = 'LOGIN',
  MANUAL_LOG_ENTRY = 'MANUAL_LOG_ENTRY',
  DOCUMENT_FILE = 'DOCUMENT_FILE',
  BILLING = 'BILLING',
  METER_NETWORK = 'METER_NETWORK',
  SUPPORT_HOUR = 'SUPPORT_HOUR',
  SYSTEM_SETTINGS = 'SYSTEM_SETTINGS',
  SET_DATA_POINT_VALUE = 'SET_DATA_POINT_VALUE',
  USER_GROUP = 'USER_GROUP',
  USER_ROLE = 'USER_ROLE',
  DATA_EXCHANGE = 'DATA_EXCHANGE',
  REPORTING = 'REPORTING'
}
