/* istanbul ignore file */
/* tslint:disable */

export enum IoTDataPointType {
  TEMPERATURE = 'TEMPERATURE',
  HUMIDITY = 'HUMIDITY',
  BATTERY_PERCENTAGE = 'BATTERY_PERCENTAGE',
  ATMOSPHERIC_PRESSURE_ABSOLUTE = 'ATMOSPHERIC_PRESSURE_ABSOLUTE',
  TEMPERATURE_ALT1 = 'TEMPERATURE_ALT1',
  CO2 = 'CO2',
  CO2_FILTERED = 'CO2_FILTERED',
  TEMPERATURE_ALT2 = 'TEMPERATURE_ALT2',
  VCAP1 = 'VCAP1',
  VCAP2 = 'VCAP2',
  STATUS = 'STATUS',
  IR_RAW = 'IR_RAW',
  IR_FILTERED = 'IR_FILTERED',
  BATTERY_VOLTAGE = 'BATTERY_VOLTAGE',
  ATMOSPHERIC_PRESSURE = 'ATMOSPHERIC_PRESSURE',
  RAT_TRAP_COUNTER = 'RAT_TRAP_COUNTER',
  RAT_TRAP_CLOSED = 'RAT_TRAP_CLOSED',
  BATTERY_LOW = 'BATTERY_LOW',
  TEMPORARY_ERROR = 'TEMPORARY_ERROR',
  PERMANENT_ERROR = 'PERMANENT_ERROR',
  VOLUME = 'VOLUME',
  WATER_LEAK_DETECTOR_1 = 'WATER_LEAK_DETECTOR_1',
  WATER_LEAK_DETECTOR_2 = 'WATER_LEAK_DETECTOR_2',
  WATER_LEAK_DETECTOR_3 = 'WATER_LEAK_DETECTOR_3',
  BUTTON = 'BUTTON',
  DIGITAL_INPUT_1 = 'DIGITAL_INPUT_1',
  DIGITAL_INPUT_2 = 'DIGITAL_INPUT_2',
  INO_INPUT_1 = 'INO_INPUT_1',
  INO_INPUT_1_COUNTER = 'INO_INPUT_1_COUNTER',
  INO_INPUT_2 = 'INO_INPUT_2',
  INO_INPUT_2_COUNTER = 'INO_INPUT_2_COUNTER',
  INO_INPUT_3 = 'INO_INPUT_3',
  INO_INPUT_3_COUNTER = 'INO_INPUT_3_COUNTER',
  INO_INPUT_4 = 'INO_INPUT_4',
  INO_INPUT_4_COUNTER = 'INO_INPUT_4_COUNTER',
  INO_INPUT_5 = 'INO_INPUT_5',
  INO_INPUT_5_COUNTER = 'INO_INPUT_5_COUNTER',
  INO_INPUT_6 = 'INO_INPUT_6',
  INO_INPUT_6_COUNTER = 'INO_INPUT_6_COUNTER',
  INO_INPUT_7 = 'INO_INPUT_7',
  INO_INPUT_7_COUNTER = 'INO_INPUT_7_COUNTER',
  INO_INPUT_8 = 'INO_INPUT_8',
  INO_INPUT_8_COUNTER = 'INO_INPUT_8_COUNTER',
  INO_INPUT_9 = 'INO_INPUT_9',
  INO_INPUT_9_COUNTER = 'INO_INPUT_9_COUNTER',
  INO_INPUT_10 = 'INO_INPUT_10',
  INO_INPUT_10_COUNTER = 'INO_INPUT_10_COUNTER',
  INO_OUTPUT_1 = 'INO_OUTPUT_1',
  INO_OUTPUT_2 = 'INO_OUTPUT_2',
  INO_OUTPUT_3 = 'INO_OUTPUT_3',
  INO_OUTPUT_4 = 'INO_OUTPUT_4',
  VOC = 'VOC',
  LIGHT_DENSITY = 'LIGHT_DENSITY',
  PIR = 'PIR',
  MOTION_DETECTION = 'MOTION_DETECTION',
  CO2_ALL_SENSE = 'CO2_ALL_SENSE',
  COUNTER = 'COUNTER',
  THERMO_SENSE_1 = 'THERMO_SENSE_1',
  THERMO_SENSE_2 = 'THERMO_SENSE_2',
  WATER_LEVEL = 'WATER_LEVEL',
  LIQUID_LEVEL = 'LIQUID_LEVEL',
  ENERGY = 'ENERGY',
  POWER = 'POWER',
  FLOW = 'FLOW',
  FORWARD_TEMPERATURE = 'FORWARD_TEMPERATURE',
  RETURN_TEMPERATURE = 'RETURN_TEMPERATURE',
  PARKING_STATUS = 'PARKING_STATUS',
  PARKING_USAGE = 'PARKING_USAGE',
  DISTANCE = 'DISTANCE',
  SIGNAL_DISRUPTION = 'SIGNAL_DISRUPTION',
  DEVICE_FAILURE = 'DEVICE_FAILURE',
  VALVE_POSITION = 'VALVE_POSITION',
  FLOW_TEMPERATURE = 'FLOW_TEMPERATURE',
  AMBIENT_TEMPERATURE = 'AMBIENT_TEMPERATURE',
  ROOM_TEMPERATURE = 'ROOM_TEMPERATURE',
  SET_POINT_TEMPERATURE_RECORDED = 'SET_POINT_TEMPERATURE_RECORDED',
  SET_POINT_TEMPERATURE_WRITTEN = 'SET_POINT_TEMPERATURE_WRITTEN'
}
