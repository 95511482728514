import {inject} from '@angular/core';
import {Router, UrlTree} from '@angular/router';
import {SubscriptionResult} from 'apollo-angular';
import {Observable, of} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {PanelStatus} from 'types';
import {PanelInfoGQL, PanelInfoSubscription} from '../../panel.generated';

export const checkPanelStatus =
  (router: Router, loggedIn: boolean = false) =>
  (response: SubscriptionResult<PanelInfoSubscription>) => {
    if (response.data.panelInfo.panelStatus === PanelStatus.Unregistered) {
      return router.createUrlTree(['activate', 'panel-registration']);
    } else if (response.data.panelInfo.panelStatus === PanelStatus.Registered) {
      return router.createUrlTree(['activate', 'panel-integrate']);
    } else if (response.data.panelInfo.panelStatus === PanelStatus.Integrated) {
      return router.createUrlTree(['activate', 'panel-activate']);
    } else if (response.data.panelInfo.panelStatus === PanelStatus.Unlinking) {
      return router.createUrlTree(['activate', 'panel-unlink']);
    } else if (!loggedIn && [PanelStatus.Importing, PanelStatus.Error].includes(response.data.panelInfo.panelStatus)) {
      return router.createUrlTree(['activate', 'panel-import']);
    }
    return true;
  };

export function panelStatusGuard(): Observable<boolean | UrlTree> {
  if (location.host === 'localhost') {
    const router = inject(Router);
    return inject(PanelInfoGQL)
      .subscribe()
      .pipe(take(1), map(checkPanelStatus(router)));
  } else {
    return of(true);
  }
}
