import {Routes} from '@angular/router';
import {marker} from '@ngneat/transloco-keys-manager/marker';
import {AutoLoginAllRoutesGuard} from 'angular-auth-oidc-client';
import {currentUserCanActivateGuard} from './core/guards/current-user.guard';
import {panelStatusGuard} from './core/guards/panel-status.guard';
import {LandingPageComponent} from './core/landing-page/landing-page.component';
import {ExportComponent} from './export.component';

export const appRoutes: Routes = [
  {
    path: 'login',
    pathMatch: 'full',
    redirectTo: ''
  },
  {
    path: '',
    pathMatch: 'full',
    canMatch: [panelStatusGuard],
    canActivate: [AutoLoginAllRoutesGuard],
    component: LandingPageComponent
  },
  {
    path: 'client/:clientId',
    canMatch: [panelStatusGuard],
    canActivate: [AutoLoginAllRoutesGuard, currentUserCanActivateGuard],
    loadChildren: () => import('./client/client.routes').then(r => r.ClientRoutes)
  },
  {
    // route used for reporting using the special auth interceptor
    path: 'reporting/client/:clientId',
    canActivate: [currentUserCanActivateGuard],
    loadChildren: () => import('./client/client.routes').then(r => r.ClientRoutes)
  },
  {
    path: 'admin',
    canActivate: [AutoLoginAllRoutesGuard, currentUserCanActivateGuard],
    loadChildren: () => import('./admin/admin.routes').then(r => r.AdminRoutes)
  },
  {
    path: 'qr',
    loadComponent: () => import('./qr-navigation/qr-navigation.component').then(c => c.QrNavigationComponent),
    canActivate: [AutoLoginAllRoutesGuard, currentUserCanActivateGuard]
  },
  {
    path: 'verifyEmail',
    loadComponent: () =>
      import('./access-control/user-email-confirmation/user-email-confirmation.component').then(c => c.UserEmailConfirmationComponent),
    data: {
      title: marker('APP_TITLE.EMAIL_CONFIRMATION')
    }
  },
  {
    path: 'slides/:slideId/token/:token',
    loadComponent: () => import('./dashboards/widgets/slide-show/slide-show/slide-show.component').then(c => c.SlideShowComponent),
    data: {
      title: marker('APP_TITLE.SLIDE_SHOW_WIDGET')
    }
  },
  {path: 'export/:token/:clientId/:groupId/:dashboardId', component: ExportComponent},
  {path: 'export/:token/:clientId/:groupId/:dashboardId/:widgetId/:widgetType', component: ExportComponent},
  {path: 'activate', loadChildren: () => import('./access-control/access-control.routes').then(r => r.AccessControlRoutes)},
  {path: '**', loadComponent: () => import('./core/notFound/not-found.component').then(c => c.NotFoundComponent)}
];
