/* istanbul ignore file */
/* tslint:disable */

export enum TableReportColumnType {
  TEXT = 'text',
  DATA_POINT = 'dataPoint',
  VALUE = 'value',
  VALUE_DATE = 'valueDate',
  VALUE_NO_UNIT = 'valueNoUnit',
  SYSTEM_NAME = 'systemName',
  PLAUSIBILITY_CHECK = 'plausibilityCheck'
}
