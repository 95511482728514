/* istanbul ignore file */
/* tslint:disable */

export enum MarkerStyle {
  NONE = 'none',
  CIRCLE = 'circle',
  SQUARE = 'square',
  DIAMOND = 'diamond',
  TRIANGLE = 'triangle',
  TRIANGLE_DOWN = 'triangle_down'
}
