/* istanbul ignore file */
/* tslint:disable */

export enum Language {
  DA = 'da',
  DE = 'de',
  DE_DE = 'de-DE',
  DE_AT = 'de-AT',
  DE_CH = 'de-CH',
  DE_LI = 'de-LI',
  DE_LU = 'de-LU',
  EN = 'en',
  EN_GB = 'en-GB',
  ES = 'es',
  FR = 'fr',
  FR_FR = 'fr-FR',
  FR_CH = 'fr-CH',
  FR_LU = 'fr-LU',
  IT = 'it',
  IT_IT = 'it-IT',
  IT_CH = 'it-CH',
  NL = 'nl'
}
