import {DeploymentType} from 'api/entities';

export const AvelonCloudBrand = 'Avelon Cloud';
export const AlcedoInhouseBrand = 'Alcedo Inhouse';

export const AvelonCloudHost = 'avelon.cloud';
export const AvelonCloudUrl = 'https://' + AvelonCloudHost;

export class BrandService {
  static getPlatformTitle(deploymentType: DeploymentType): string {
    if (location.hostname.includes('wago')) {
      return 'WAGO Cloud';
    } else {
      return BrandService.getAvelonServerTitle(deploymentType);
    }
  }

  static getAvelonServerTitle(deploymentType: DeploymentType): string {
    return deploymentType === DeploymentType.CLOUD ? AvelonCloudBrand : AlcedoInhouseBrand;
  }
}
