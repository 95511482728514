import {HttpContext, HttpContextToken, HttpHeaders} from '@angular/common/http';

export const SUPPRESS_ERROR_TOAST = new HttpContextToken<boolean>(() => false);

/**
 * By default, all GET request are cached and all other requests have the default behaviour.
 * However, you can configure the requests as follows:
 *
 * For GET requests you can set:
 * getCache === false - to force the request and cache the response
 * getCache === true - to get the response from cache without making the request; if no cached response found return EMPTY observable
 * getCache === undefined - to get the response from cache or make the request if no cache found
 *
 * For POST (or other) requests you can set:
 * behaveAsGET === true - to make the request behave as a GET request (see above)
 * behaveAsGET === false | undefined - to keep the default behaviour.
 *
 * IMPORTANT NOTE: "behaveAsGET" parameter must be set to true if the "getCache" parameter is set to true/false
 */
export function getCacheHeaders(getCache?: boolean, behaveAsGET?: boolean) {
  const headers: any = typeof getCache === 'boolean' ? {'x-cache': getCache.toString()} : {'x-cache': ''};
  if (behaveAsGET) {
    headers.behaveAsGET = behaveAsGET.toString();
  }
  return {headers: new HttpHeaders(headers)};
}

export function getSuppressErrorToastContext(): HttpContext {
  return new HttpContext().set(SUPPRESS_ERROR_TOAST, true);
}

export function formatPhoneNumber(phoneNo: string) {
  return phoneNo ? phoneNo.replace(/ /g, '').replace('+', '00') : phoneNo;
}
