export class TrimmingInterceptorHandle {
  private exceptions = [
    {
      url: /(\/iotDeviceProfiles\/\d+)$/,
      properties: /decodingFunction/
    },
    {
      url: /(\/clients\/\d+)$/,
      properties: /breadcrumbSeparator/
    },
    {
      url: /(\/clients\/\d+\/notificationTemplates)$/,
      properties: /(\[\d+]\.(contents|subject))$/
    },
    {
      url: /(\/alarmTextFormats)$/,
      properties: /(\[\d+]\.format)$/
    },
    {
      url: /(\/faqs\/\d+)$/,
      properties: /(answer|question)/
    },
    {
      url: /(\/shapes\/update)$/,
      properties: /(\[\d+]\.htmlText)$/
    },
    {
      url: /(\/shapeTypes\/\d+)$/,
      properties: /visualization/
    },
    {
      url: /(\/exchange\/export\/\d+)$/,
      properties: /lineEnd/
    }
  ];

  static trimProperties(value: any, exceptions: RegExp, path?: string) {
    path = path || '';

    if (Array.isArray(value)) {
      this.forEachArray(value, exceptions, path);
    } else if (TrimmingInterceptorHandle.isPlainObject(value)) {
      this.forEachObject(value, exceptions, path);
    }
  }

  private static isPlainObject(value) {
    return value?.constructor === Object;
  }

  private static canTrimValue(value: string, propertyPath: string, exceptions: RegExp): boolean {
    if (typeof value !== 'string') {
      return false;
    }
    return !exceptions?.test(propertyPath);
  }

  private static forEachArray(array: any[], exceptions: RegExp, path?: string) {
    array.forEach((value, index, arr) => {
      const deepPath = `${path}[${index}]`;

      if (TrimmingInterceptorHandle.canTrimValue(arr[index], deepPath, exceptions)) {
        arr[index] = arr[index].trim();
      }
      this.trimProperties(arr[index], exceptions, deepPath);
    });
  }

  private static forEachObject(object: any, exceptions: RegExp, path?: string) {
    for (const key in object) {
      const deepPath = path ? `${path}.${key}` : key;
      if (TrimmingInterceptorHandle.canTrimValue(object[key], deepPath, exceptions)) {
        object[key] = object[key].trim();
      }
      this.trimProperties(object[key], exceptions, deepPath);
    }
  }

  getExceptions(url: string): RegExp {
    return this.exceptions.find(exception => exception.url.test(url))?.properties;
  }
}
