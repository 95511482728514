/* istanbul ignore file */
/* tslint:disable */

export enum PartitionType {
  DATABASE = 'DATABASE',
  LOGS = 'LOGS',
  APPLICATION_DATA = 'APPLICATION_DATA',
  CUSTOMER_DATA = 'CUSTOMER_DATA',
  DOCKER_BEETLE_DATA = 'DOCKER_BEETLE_DATA'
}
